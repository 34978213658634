<template>
    <div class="guest flex-center flex-col">
        <div class="guest-avatar-holder">
            <div class="guest-avatar" :style="{backgroundImage: `url(${avatar})`}"></div>
        </div>
        <div class="guest-name">{{ name }}</div>
    </div>
</template>

<script>
export default {
    props: ["name", "avatar"],
    name: "Guest"
};
</script>

<style scoped>
    .guest {
        width: 100%;
    }
    .guest-avatar-holder {
        width: 4em;
        height: 4em;
        margin-bottom: 1em;
    }
    .guest-avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #333;
    }
</style>
