<template>
    <div class="row d-flex flex-center h-100-fill horizontal-pad" id="guest-row">
        <div class="guest-row flex-row guest-cams" v-if="camGuests.length">
            <GuestCam v-for="guest in camGuests" :name="guest.name" v-bind:key="guest.name"/>
        </div>
        <div class="guest-row flex-row guest-no-cams" v-if="noCamGuests.length">
            <Guest v-for="guest in noCamGuests" :name="guest.name" v-bind:key="guest.name"/>
        </div>
<!--        <Guest name="Tridd"/>-->
<!--        <Guest name="Jaws"/>-->
<!--        <Guest name="flame"/>-->
<!--        <Guest name="yeHHH"/>-->
<!--        <Guest name="Solomon"/>-->
<!--        <Guest name="Surefour"/>-->
<!--        <Guest name="McGravy"/>-->
<!--        <Guest name="ZP"/>-->
    </div>
</template>

<script>
import Guest from "@/components/Guest";
import GuestCam from "@/components/GuestCam";
export default {
    name: "GuestRow",
    components: { Guest, GuestCam },
    data: () => ({
        guests: [
            { name: "Tridd", "use_cam": true, "cam_code": "tridd" },
            { name: "Jaws", "use_cam": true },
            { name: "flame", "use_cam": false },
            { name: "yeHHH", "use_cam": false },
            { name: "Solomon", "use_cam": false },
            { name: "Surefour", "use_cam": false },
            { name: "McGravy", "use_cam": false },
            { name: "ZP", "use_cam": false },
        ]
    }),
    computed: {
        camGuests() { return this.guests.filter(g => g.use_cam); },
        noCamGuests() { return this.guests.filter(g => !g.use_cam); },
    }
};
</script>

<style scoped>
    #guest-row {
        align-items: flex-start;
    }
    .guest-row {
        width: 100%;
        height: 100%;
    }
    .guest-row > div {
        margin: 0 10px;
    }
    .guest-row > div:first-child { margin-left: 0; }
    .guest-row > div:last-child { margin-right: 0; }

    .guest-no-cams, .guest-cams {
        flex-shrink: 1 !important;
    }
</style>
