<template>
    <div class="pred-display" :style="background">
        <div class="pred-name flex-center" :style="textStyle">{{ name }}</div>
        <div class="pred-lower flex-row">
            <TeamLogo padding=".5em" :code="team"></TeamLogo>

            <div class="pred-scoreline" :style="scorelineStyle">{{ scoreline }}</div>
        </div>
    </div>
</template>

<script>
import TeamLogo from "@/components/TeamLogo";
import { logoStyles, teamStyles } from "@/tools/team-styling";

export default {
    props: ["name", "scoreline", "team"],
    name: "PredDisplay",
    components: {
        TeamLogo
    },
    computed: {
        background() { return { backgroundColor: logoStyles[this.team || "TBD"].backgroundColor }; },
        textStyle() { return teamStyles[this.team || "TBD"]; },
        scorelineStyle() { return {color: teamStyles[this.team || "TBD"].nonThemedColor}; },
    },
    data: () => ({
        logoStyles
    })
};
</script>

<style scoped>
    .pred-name {
        height: 1.5em;
        text-transform: uppercase;
        background-color: rgba(0,0,0, 0.2)
    }
    .pred-display {
        width: 6em;
    }
    .pred-lower {
        align-items: center;
    }
    .pred-lower .team-logo-holder {
        width: 2.75em;
        height: 2.25em;
        flex-shrink: 0;
    }
    .pred-scoreline {
        font-size: 1.4em;
        font-weight: bold;
        width: 2em;
        text-align: center;
    }
</style>
