<template>
    <div class="replacement-overlay" :style="cssVars" v-if="companion">
        <div class="members">
            <ReplacementMembers />
        </div>
    </div>
</template>

<script>
import {getCompanion} from "@/tools/data-fetch";
import ReplacementMembers from "@/components/externals/ReplacementMembers";

export default {
    name: "ReplacementOverlay",
    components: {ReplacementMembers},
    data: () => ({
        companion: null
    }),
    async beforeRouteEnter(to, from, next) {
        let companion = await getCompanion("avast");
        next(component => {
            component.companion = companion;
        });
    },
    computed: {
        cssVars() {
            return {
                "--color": this.companion.color || "",
                "--text-on-color": this.companion.text_on_color || "",
                "--font-size": this.companion.size ? this.companion.size + "px" : ""
            };
        }
    },
    mounted() {
        console.log("mounted", this.companion);
        this.$socket.client.emit("subscribe:discord", this.companion.channel[0]);
    },
    sockets: {
        connect() {
            // for reconnect
            this.$socket.client.emit("subscribe:discord", this.companion.channel[0]);
        },
        discord_speaking(userID, isSpeaking) {
            console.log(userID, isSpeaking);
        },
    }
};
</script>

<style scoped>

</style>
