<template>
    <div class="member-list">
<!--        <transition-group name="member-list">-->
            <ReplacementMemberItem v-for="member in membersSpeaking" v-bind:key="member.id" :member="member" />
<!--        </transition-group>-->
    </div>
</template>

<script>
import ReplacementMemberItem from "@/components/externals/ReplacementMemberItem";
export default {
    name: "ReplacementMembers",
    components: { ReplacementMemberItem },
    data: () => ({
        speaking: [],
        members: []
    }),
    mounted() {
        // setInterval(() => {
        //     this.members.splice(Math.floor(this.members.length * Math.random()), 1);
        // }, 5000);
    },
    computed: {
        membersSpeaking() {
            return this.members.map(m => ({
                ...m,
                speaking: this.speaking.includes(m.id)
            })).sort((a,b) => {
                if (a._permanent !== b._permanent) {
                    if (a._permanent) return 1;
                    if (b._permanent) return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return -1;
            });
        }
    },
    sockets: {
        connect() {
            this.speaking = [];
        },
        discord_speaking(id, isSpeaking) {
            if (isSpeaking) {
                if (!this.speaking.includes(id)) this.speaking.push(id);
            } else {
                if (this.speaking.includes(id)) this.speaking = this.speaking.filter(_i => _i !== id);
            }
        },
        discord_leave(id) {
            this.members = this.members.filter(m => m.id !== id);
        },
        discord_join(member) {
            this.members.push(member);
        },
        discord_members(members) {
            this.members = members;
        }
    }
};
</script>

<style scoped>
.member-list {
    margin: .25em;
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
}
</style>

<style>
/*.member-list-enter-active, .member-list-leave-active {*/
/*    transition: all 2s*/
/*}*/
.member {
    transition: all .5s;
}
.member-list-move:not(.member-list-leave-to) {
    /*--color: blue;*/
    transition-delay: .4s;
}
.member-list-leave-to, .member-list-enter-from {
    opacity: 0;
    transform: scale(0.2);
    transform-origin: left;
}
.member-list-leave-active {
    position: absolute;
}
</style>
