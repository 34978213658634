<template>
    <div class="member" :style="position" v-if="member.show_user">
        <div class="member-avatar-holder" v-bind:class="{'speaking': member.show_avatar && member.speaking}">
            <div class="member-avatar" :style="{backgroundImage: `url(${member.avatar})`}" v-show="member.show_avatar"></div>
        </div>
        <div class="member-name">{{ member.name }}</div>


        <transition mode="out-in" name="pred">
            <Prediction v-if="member.prediction" :prediction="member.prediction" class="member-pred"/>
        </transition>
    </div>
</template>

<script>
import Prediction from "@/components/externals/Prediction";

export default {
    name: "MemberItem",
    components: {
        Prediction
    },
    props: ["member"],
    computed: {
        position() {
            console.log(this.member.seat);

            let seats = {
                "1": { top: 480, left: 455 },
                "2": { top: 461, left: 752 },
                "3": { top: 455, left: 1030 },
                "4": { top: 500, left: 1340 },
                "5": { top: 1070, left: 350, zoom: 0.5 },
                "6": { top: 1070, left: 635, zoom: 0.5 },
                "7": { top: 1020, left: 3100, zoom: 0.5 },
                "8": { top: 1030, left: 3385, zoom: 0.5 },
                "9": { top: 965, left: 2470, zoom: 0.5 },
            };

            let seat = seats[this.member.seat];
            if (!seat) {
                seat = { top: 700, left: 1650, zoom: 1};
            }
            return {
                ...seat,
                top: seat.top + "px",
                left: seat.left + "px",
            };
        }
    }
};
</script>

<style scoped>
.member {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: .1em 0;

    position: absolute;
}

.member-avatar-holder {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: .15em solid rgba(255,255,255,0);
    overflow: hidden;
    margin-right: .25em;
    margin-bottom: .25em;

    width: 150px;
    height: 150px;
}
.member-avatar {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.member-avatar-holder.speaking {
    border-color: var(--color, #1ddb99);
}

.member-name {
    background: var(--color, #222222);
    color: var(--text-on-color, #ffffff);

    background-color: #222;
    color: #ffff;
    border-radius: .1em;
    padding: 0 .3em;
}

.pred-enter-active, .pred-leave-active {
    transition: all .5s ease;
    overflow: hidden;
    white-space: nowrap;
}
.pred-enter-to, .pred-leave-from {
    max-width: 6.5em;
}
.pred-enter-from, .pred-leave-to {
    max-width: 0;
    padding-right: 0;
}
.member-pred {
    margin-left: 0;
    font-size: 25px;
    margin-top: .5em;
}
</style>
