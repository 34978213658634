<template>
    <div class="team-logo-holder flex-center">
        <div class="team-logo bg-center" :style="logoStyle"></div>
    </div>
</template>

<script>
import {logoStyles} from "@/tools/team-styling";

export default {
    props: ["url", "padding", "code"],
    name: "TeamLogo",
    computed: {
        logoStyle() { return {
            width: `calc(100% - ${this.padding || "0"})`,
            height: `calc(100% - ${this.padding || "0"})`,
            backgroundImage: this.url ? `url(${this.url})` : logoStyles[this.code || "TBD"].backgroundImage
        };}
    }
};
</script>

<style scoped>
</style>
