<template>
    <transition name="game-holder">
        <div class="game-holder flex-row" v-if="game">
            <div class="team flex-col" v-for="(team, ti) in game.teams"
                 :style="{order: ti*2, ...background(team.code)}" v-bind:key="team.code"
            >
                <TeamLogo padding=".5em" :code="team.code"></TeamLogo>
                <div class="team-score">{{ game.score[ti] }}</div>
            </div>
            <div class="game-display" :style="{order: 1}"></div>
        </div>
    </transition>
</template>

<script>
import TeamLogo from "@/components/TeamLogo";
import { logoStyles } from "@/tools/team-styling";

export default {
    name: "GameDisplay",
    components: {
        TeamLogo
    },
    methods: {
        background(teamCode) {
            return {
                backgroundColor: logoStyles[teamCode || "TBD"].backgroundColor
            };
        }
    },
    data: () => ({
        game: {
            score: [3, 1],
            teams: [
                { code: "HOU", },
                { code: "DAL", }
            ]
        }
    })
};
</script>

<style scoped>
    .game-holder {
        width: 100%;
        height: 7em;
    }
    .team {
        width: 4em;
        height: 100%;
        flex-shrink: 0;
        align-items: center;
    }
    .game-display {
        width: 100%;
        height: 100%;
        background-color: orange;
    }

    .game-display {
        background: url(https://cdn.discordapp.com/attachments/577862338384756796/832774473702309918/unknown.png);
        background-position: -926px -40px;
        background-size: 430%;
    }
    .team-logo-holder {
        width: 4em;
        height: 4em;
        flex-shrink: 0;
    }
    .team-score {
        font-size: 96px;
        font-weight: bold;
    }

</style>
