<template>
    <div id="avast-cam"></div>
</template>

<script>
export default {
    name: "AvastCamera"
};
</script>

<style scoped>
    #avast-cam {
        width: 16em;
        height: 14em;
        background: white url(https://owcdn.net/img/5bd4c420efe51.jpg) no-repeat center;
        background-size: cover;
    }
</style>
