import AvastTestOverlay from "@/components/AvastTestOverlay";
import * as Router from "vue-router";
import ExternalOverlay from "@/components/externals/ExternalOverlay";
import ReplacementOverlay from "@/components/ReplacementOverlay";

const routes = [
    {
        path: "/external/:companion",
        component: ExternalOverlay,
        props: (route) => ({
            align: route.query.align || "left",
            verticalAlign: route.query.valign || "top",
        })
    },
    {path: "/testing/avast", component: AvastTestOverlay},
    {path: "/replacement", component: ReplacementOverlay}
];

export default Router.createRouter({
    history: Router.createWebHistory(),
    routes
});
