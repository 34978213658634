<template>
    <transition name="pred" mode="out-in">
        <div class="pred" v-if="predictedTeam" v-bind:class="{ boomed }" v-bind:key="generatedKey">
            <div class="pred-team-logo-holder" :style="teamLogoBackground">
                <div class="pred-team-logo" :style="teamLogo"></div>
            </div>
            <div class="pred-text">
                {{ predictedTeam.code }} {{ scoreline }}
            </div>

            <transition name="anim-boom">
                <div class="boomed-badge" v-if="boomed">BOOMED</div>
            </transition>
        </div>
    </transition>
</template>

<script>
export default {
    name: "Prediction",
    props: ["prediction"],
    mounted() {
        console.log(JSON.parse(JSON.stringify(this.prediction)));
        console.log(this.predictedTeam);
    },
    computed: {
        generatedKey() {
            return `${this.prediction.match.match_code}-${this.prediction.scoreline}`;
        },
        isEmpty() {
            return this.prediction.score_0 === 0 && this.prediction.score_1 === 0;
        },
        predictedTeam() {
            if (this.isEmpty) return null;
            return this.prediction.match.teams[this.prediction.score_0 === this.prediction.match.first_to ? 0 : 1];
        },
        teamLogo() { return { backgroundImage: `url(${ this.predictedTeam.primary_logo || this.predictedTeam.logo })` }; },
        teamLogoBackground () { return { backgroundColor: this.predictedTeam.logo_background_color }; },
        boomed() {
            let prediction = [this.prediction.score_0, this.prediction.score_1];
            let actual = [this.prediction.match.score_0, this.prediction.match.score_1];
            if ([0,1].some(i => actual[i] > prediction[i])) return true;
            if (actual.some(score => score === this.prediction.match.first_to)) {
                // match finished
                return (JSON.stringify(prediction) !== JSON.stringify(actual));
            }
            return false;
        },
        scoreline() {
            if (!this.prediction) return "";
            if (this.prediction.score_1 === this.prediction.match.first_to) {
                return this.prediction.scoreline.split("").reverse().join("");
            }
            return this.prediction.scoreline;
        }
    }
};
</script>

<style scoped>
    .pred {
        border-radius: .1em;
        padding-right: 0.3em;
        background-color: #000;
        color: white;
        /*margin-left: .3em;*/
        display: inline-flex;
        position: relative;
        transition: all .5s ease;
    }
    .pred.boomed {
        background-color: #555;
        color: #ddd;
    }
    .pred-team-logo-holder {
        height: 1.325em;
        width: 1.5em;
        margin-right: .3em;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-top-left-radius: .1em;
        border-bottom-left-radius: .1em;
        flex-shrink: 0;
    }
    .pred-team-logo {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: calc(100% - .1em);
        height: calc(100% - .2em);
    }
    .boomed-badge {
        position: absolute;
        padding: 0 .3em;
        background-color: red;
        top: 1.3em;
        left: -.15em;
        transform: rotate(-20deg);
        font-size: .35em;
        border-radius: 4px;
        overflow: hidden;
        color: #fff;
    }

    .pred-enter-active, .pred-leave-active {
        transition: all .5s ease;
        overflow: hidden;
        white-space: nowrap;
    }
    .pred-enter-to, .pred-leave-from {
        max-width: 6.5em;
    }
    .pred-enter-from, .pred-leave-to {
        max-width: 0;
        padding-right: 0;
    }


    .anim-boom-enter-active, .anim-boom-leave-active {
        transition: all .5s ease;
    }
    .anim-boom-enter-to, .anim-boom-leave-from {
        opacity: 1;
        transform: scale(1) rotate(-20deg);
    }
    .anim-boom-enter-from, .anim-boom-leave-to {
        opacity: 0;
        transform: scale(0.2) rotate(-20deg);
    }
</style>
