<template>
    <div class="guest-cam">
        <div class="guest-cam-frame"></div>
        <div class="guest-name d-flex">{{ name }}</div>
    </div>
</template>

<script>
export default {
    props: ["name", "cam_code"],
    name: "GuestCam"
};
</script>

<style scoped>
    .guest-cam {
        width: 100%;
        height: 100%;
    }
    .guest-cam-frame {
        width: 100%;
        height: 100%;
        background: #222;
    }
    .guest-name {
        position: absolute;
        bottom: 1em;
        height: 1.2em;
        background-color: white;
        color: black;
        padding: .25em .5em;
        margin: .5em;
    }
</style>
