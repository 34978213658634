<template>
    <div class="member-list">
        <transition-group name="member-list">
            <MemberItem v-for="member in membersSpeaking" v-bind:key="member.id" :member="member" />
        </transition-group>
    </div>
</template>

<script>
import MemberItem from "@/components/externals/MemberItem";
export default {
    name: "MemberList",
    props: ["companion", "members"],
    components: { MemberItem },
    data: () => ({
        speaking: []
    }),
    mounted() {
        // setInterval(() => {
        //     this.members.splice(Math.floor(this.members.length * Math.random()), 1);
        // }, 5000);
    },
    computed: {
        host() {
            if (!this.companion) return null;
            return this.companion.host[0] || null;
        },
        membersSpeaking() {
            if (this.host && !this.members.find(m => m.airtableID === this.host)) {
                console.warn("Host isn't in channel");
                return [];
            }
            return this.members.filter(m => this.companion.show_host ? true : m.airtableID !== this.host).map(m => ({
                ...m,
                speaking: this.speaking.includes(m.id)
            })).sort((a,b) => {
                if (a._permanent !== b._permanent) {
                    if (a._permanent) return 1;
                    if (b._permanent) return -1;
                }
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return -1;
            });
        }
    },
    sockets: {
        connect() {
            this.speaking = [];
        },
        discord_speaking(id, isSpeaking) {
            if (isSpeaking) {
                if (!this.speaking.includes(id)) this.speaking.push(id);
            } else {
                if (this.speaking.includes(id)) this.speaking = this.speaking.filter(_i => _i !== id);
            }
        }
    }
};
</script>

<style scoped>
.member-list {
    margin: .25em;
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
}
</style>

<style>
/*.member-list-enter-active, .member-list-leave-active {*/
/*    transition: all 2s*/
/*}*/
.member {
    transition: all .5s;
}
.member-list-move:not(.member-list-leave-to) {
    /*--color: blue;*/
    transition-delay: .4s;
}
.member-list-leave-to, .member-list-enter-from {
    opacity: 0;
    transform: scale(0.2);
    transform-origin: left;
}
.member-list-leave-active {
    position: absolute;
}
</style>
