<template>
    <div id="external-overlay" v-if="companion" :style="cssVars" :class="`align-${align} vertical-${verticalAlign}`">
        <MemberList :companion="companion" :members="members" />
    </div>
</template>

<script>
import {getCompanion} from "@/tools/data-fetch";
import MemberList from "@/components/externals/MemberList";

export default  {
    name: "ExternalOverlay",
    props: ["align", "verticalAlign"],
    components: { MemberList },
    data: () => ({
        companion: null,
        members: []
    }),
    async beforeRouteEnter(to, from, next) {
        let companion = await getCompanion(to.params.companion);
        next(component => {
            component.companion = companion;
        });
    },
    computed: {
        cssVars() {
            return {
                "--color": this.companion.color || "",
                "--text-on-color": this.companion.text_on_color || "",
                "--font-size": this.companion.size ? this.companion.size + "px" : ""
            };
        }
    },
    mounted() {
        console.log("mounted", this.companion);
        this.$socket.client.emit("subscribe:discord", this.companion.channel[0]);
    },
    sockets: {
        connect() {
            // for reconnect
            this.$socket.client.emit("subscribe:discord", this.companion.channel[0]);
        },
        discord_speaking(userID, isSpeaking) {
            console.log(userID, isSpeaking);
        },
        discord_leave(id) {
            this.members = this.members.filter(m => m.id !== id);
        },
        discord_join(member) {
            this.members.push(member);
        },
        discord_members(members) {
            this.members = members;
        }
    }
};
</script>

<style>
    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }
    #external-overlay {
        font-size: var(--font-size, 40px);
    }

    #external-overlay.vertical-top .member-list {
        top: 0;
    }
    #external-overlay.vertical-bottom .member-list {
        bottom: 0;
    }

    #external-overlay.align-right .member-list {
        align-items: flex-end;
        position: absolute;
        right: 0;
    }
    #external-overlay.align-right .member {
        flex-direction: row-reverse;
    }
    #external-overlay.align-right .member-avatar-holder,
    #external-overlay.align-right .member-name,
    #external-overlay.align-right .pred {
        margin-left: .4em;
    }
    #external-overlay.align-left .member-avatar-holder,
    #external-overlay.align-left .member-name,
    #external-overlay.align-left .pred {
        margin-right: .4em;
    }
</style>
