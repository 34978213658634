export const logoStyles = {
    "FLA": { backgroundColor: "#000000", color: "#CF4691", backgroundImage: "url(\"https://images.blz-contentstack.com/v3/assets/blt321317473c90505c/blt3899fe69781c6ce4/5e14d29bdc2ac60d1dd16e67/OWL_FloridaMayhem_Icon_2020.png\")" },
    "ATL": { backgroundColor: "#C4C4C4", color: "#910F1B", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/32/32MTX0PLEDY31542673991836.png\")" },
    "SEO": { backgroundColor: "#AA8A00", color: "#000000", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/LHRSIW3NWH211508792362796.png\")" },
    "GLA": { backgroundColor: "#3C1053", color: "#ffffff", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/3AEMOZZL76PF1508792362892.PNG\")" },
    "VAL": { backgroundColor: "#1888C6", color: "#FFD100", backgroundImage: "url(\"https://images.blz-contentstack.com/v3/assets/blt321317473c90505c/blta03a226e8c5d90d5/5e14d2882a6ac40d0b660935/OWL_LAValiant_Icon_2020_PNG.png\")" },
    "SFS": { backgroundColor: "#000000", color: "#FC4C02", backgroundImage: "url(\"https://images.blz-contentstack.com/v3/assets/blt321317473c90505c/blte679a761205b5d5f/5e1763e38691147ecf07e0f6/OWL_SFShock_Icon_1C_SILVER-01.png\")" },
    "CDH": { backgroundColor: "#FFA000", color: "#000000", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/VSAVRBWU5F321544055622417.png\")" },
    "SHD": { backgroundColor: "#D22630", color: "#FCE300", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/F7T6ISEVW0NN1512520986578.png\")" },
    "HZS": { backgroundColor: "#FB7299", color: "#ffffff", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/TJ9I5I8BFC5J1542674380020.png\")" },
    "GZC": { backgroundColor: "#122C42", color: "#0FEFD9", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/sz/SZQVDGE3F1TE1542674048320.png\")" },
    "TOR": { backgroundColor: "#C10021", color: "#000000", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/g0/G05QL2P5A92E1542674081932.png\")" },
    "WAS": { backgroundColor: "#990034", color: "#ffffff", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/95UE5OJKSFQF1543968718489.png\")" },
    "DAL": { backgroundColor: "#032340", color: "#0072CE", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/YUUL7E0CSF591544055626557.png\")" },
    "HOU": { backgroundColor: "#000000", color: "#97D700", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/gallery/46H5JPPV59Z51546557672375.png\")" },
    "PAR": { backgroundColor: "#303D56", color: "#FFAA1D", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/qm/QM7JE0THABVT1542674071412.png\")" },
    "PHI": { backgroundColor: "#000000", color: "#F99E29", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/gallery/BI9AZG2WTOCE1544642967810.png\")" },
    "BOS": { backgroundColor: "#174B97", color: "#EFDF00", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/8RS25ECY3PZH1515523733716.png\")" },
    "VAN": { backgroundColor: "#09226B", color: "#2FB228", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/gallery/K4OO4HAOUWWH1543976763453.png\")" },
    "NYE": { backgroundColor: "#171C38", color: "#0F57EA", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/9r/9RYLM8FICLJ01508818792450.png\")" },
    "LDN": { backgroundColor: "#59CBE8", color: "#1C2B39", backgroundImage: "url(\"https://bnetcmsus-a.akamaihd.net/cms/page_media/NW461AQIYQMK1508792363133.png\")" },
    "TBD": { backgroundColor: "#FFFFFF", color: "#444444", backgroundImage: "url(\"https://cdn.discordapp.com/attachments/485493459357007876/726839736852938772/unknown.png"},
};

export const teamStyles = {
    "CDH": { backgroundColor: "#FFA000", color:"#000000", nonThemedColor: "#000000" },
    "GZC": { backgroundColor: "#122C42", color:"#0FEFD9", nonThemedColor: "#FFFFFF" },
    "HZS": { backgroundColor: "#FB7299", color:"#FFFFFF", nonThemedColor: "#FFFFFF" },
    "SHD": { backgroundColor: "#D22630", color:"#FCE300", nonThemedColor: "#FFFFFF" },
    "SFS": { backgroundColor: "#A5ACAF", color:"#000000", nonThemedColor: "#FFFFFF" },
    "VAL": { backgroundColor: "#1888C6", color:"#FFD100", nonThemedColor: "#FFFFFF" },
    "VAN": { backgroundColor: "#09226B", color:"#2FB228", nonThemedColor: "#FFFFFF" },
    "GLA": { backgroundColor: "#3C1053", color:"#FFFFFF", nonThemedColor: "#FFFFFF" },
    "ATL": { backgroundColor: "#C4C4C4", color:"#910F1B", nonThemedColor: "#000000" },
    "WAS": { backgroundColor: "#990034", color:"#FFFFFF", nonThemedColor: "#FFFFFF" },
    "BOS": { backgroundColor: "#174B97", color:"#EFDF00", nonThemedColor: "#FFFFFF" },
    "TOR": { backgroundColor: "#000000", color:"#FFFFFF", nonThemedColor: "#FFFFFF" },
    "DAL": { backgroundColor: "#0072CE", color:"#000000", nonThemedColor: "#FFFFFF" },
    "FLA": { backgroundColor: "#CF4691", color:"#000000", nonThemedColor: "#FFFFFF" },
    "PAR": { backgroundColor: "#303D56", color:"#FFAA1D", nonThemedColor: "#FFFFFF" },
    "NYE": { backgroundColor: "#0F57EA", color:"#FFFFFF", nonThemedColor: "#FFFFFF" },
    "PHI": { backgroundColor: "#F99E29", color:"#000000", nonThemedColor: "#FFFFFF" },
    "HOU": { backgroundColor: "#97D700", color:"#000000", nonThemedColor: "#FFFFFF" },
    "LDN": { backgroundColor: "#59CBE8", color:"#1C2B39", nonThemedColor: "#000000" },
    "SEO": { backgroundColor: "#AA8A00", color:"#000000", nonThemedColor: "#000000" },
};
