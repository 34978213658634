<template>
    <div class="row horizontal-pad d-flex" id="top-row">
        <AvastCamera></AvastCamera>
        <div class="pred-column flex-col">
            <PredDisplay
                name="Avast"
                scoreline="3-0"
                team="LDN"/>
            <PredDisplay
                name="ELF"
                scoreline="3-1"
                team="BOS"/>
            <PredDisplay
                name="Twitch"
                scoreline="52%"
                team="VAL"/>
        </div>
        <div class="top-right vertical-pad flex-col">
            <GameDisplay/>
            <div class="community-stats flex-center">
                COMMUNITY STATS
            </div>
        </div>
    </div>
</template>

<script>
import AvastCamera from "@/components/AvastCamera";
import GameDisplay from "@/components/GameDisplay";
import PredDisplay from "@/components/PredDisplay";

export default {
    name: "TopRow",
    components: {
        AvastCamera, GameDisplay, PredDisplay
    }
};
</script>

<style scoped>
    .top-right {
        width: 100%;
        flex-shrink: 1;
    }
    .community-stats {
        background-color: white;
        color: black;
        min-height: 4em;
        flex-grow: 1;
    }
    .pred-column {
        justify-content: space-around;
    }
</style>
