<template>
    <div class="member">
        <div class="member-avatar-holder" v-bind:class="{'speaking': member.speaking}">
            <div class="member-avatar" :style="{backgroundImage: `url(${member.avatar})`}"></div>
        </div>
        <div class="member-name">{{ member.name }}</div>


        <transition name="pred" mode="out-in">
            <Prediction v-if="member.prediction" :prediction="member.prediction" />
        </transition>
    </div>
</template>

<script>
import Prediction from "@/components/externals/Prediction";

export default {
    name: "MemberItem",
    components: {
        Prediction
    },
    props: ["member"]
};
</script>

<style scoped>
.member {
    display: flex;
    align-items: center;
    margin: .1em 0;
}

.member-avatar-holder {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    border: .15em solid rgba(255,255,255,0);
    overflow: hidden;
    /*margin-right: .25em;*/
}
.member-avatar {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.member-avatar-holder.speaking {
    border-color: var(--color, #1ddb99);
}

.member-name {
    background: var(--color, #222222);
    color: var(--text-on-color, #ffffff);
    border-radius: .1em;
    padding: 0 .3em;
}

.pred-enter-active, .pred-leave-active {
    transition: all .5s ease;
    overflow: hidden;
    white-space: nowrap;
}
.pred-enter-to, .pred-leave-from {
    max-width: 6.5em;
}
.pred-enter-from, .pred-leave-to {
    max-width: 0;
    padding-right: 0;
}
</style>
