<template>
    <div id="avast-overlay" class="fullscreen d-flex pos-tl flex-col vertical-pad g-padding">
        <TopRow></TopRow>
        <GuestRow></GuestRow>
    </div>
</template>

<script>
import TopRow from "@/components/TopRow";
import GuestRow from "@/components/GuestRow";

export default {
    name: "AvastTestOverlay",
    components: {
        TopRow, GuestRow
    }
};
</script>

<style scoped>

body {
    background: #111 url(https://cdn.discordapp.com/attachments/485493459357007876/829817212784607266/grid.png);
    color: white;
}
.row {
    width: 100%;
}
</style>
