import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";

const app = createApp(App);
const socket = io(process.env.NODE_ENV === "production" ? "https://server.a2021.slmn.io" : "http://localhost:8092");

app.use(router);
app.use(VueSocketIOExt, socket);

app.mount("#app");

